<template>
  <div v-loading="loading" class="main-frame">
    <el-container>
      <el-header class="banner-head" height="60px">
        <div class="head-info">
          <el-form :inline="true" @submit.native.prevent>
            <el-form-item label="位置:">
              <el-select
                v-model="class_id"
                clearable
                placeholder="请选择"
                loading-text="加载中..."
              >
                <el-option key="class-all" label="所有" value></el-option>
                <el-option
                  v-for="item in class_list"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="状态:">
              <el-select
                v-model="page_status"
                clearable
                placeholder="请选择"
                loading-text="加载中..."
              >
                <el-option
                  v-for="item in $common.CommonStatusList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="关键字:">
              <el-input
                v-model="search_key"
                clearable
                size="medium"
                placeholder="请输入关键字"
              ></el-input>
            </el-form-item>
            <el-button
              class="top-button"
              size="medium"
              type="primary"
              round
              @click="getModelList"
              >过滤</el-button
            >
            <el-button
              class="top-button"
              size="medium"
              type="primary"
              round
              @click="checkModelDetail"
              >添加</el-button
            >
          </el-form>
        </div>
      </el-header>
      <el-main>
        <el-table
          :data="model_list"
          @sort-change="sortTable"
          style="width: 100%"
          :cell-style="$common.tableStyle"
          :header-cell-style="$common.tableStyle"
          border
          default-expand-all
        >
          <el-table-column label="#" width="80" align="center">
            <template slot-scope="scope">
              <p style="font-weight: bold">{{ scope.$index + 1 }}</p>
            </template>
          </el-table-column>
          <el-table-column label="权重" width="80" align="center">
            <template slot-scope="scope">
              <p style="font-weight: bold">{{ scope.row.number }}</p>
            </template>
          </el-table-column>
          <el-table-column label="标题" align="center">
            <template slot-scope="scope">
              <p style="font-weight: bold">{{ scope.row.title }}</p>
            </template>
          </el-table-column>
          <el-table-column label="展示位置" align="center">
            <template slot-scope="scope">
              <p style="font-weight: bold">
                {{
                  class_list_map.get(scope.row.class_id)
                    ? class_list_map.get(scope.row.class_id)
                    : "无"
                }}
              </p>
            </template>
          </el-table-column>
          <el-table-column
            prop="start_at"
            sortable="start_at"
            label="开始展示时间"
          >
            <template #default="scope">
              <p style="font-weight: bold">
                {{ $common.formatTimesToTime(scope.row.start_at) }}
              </p>
            </template>
          </el-table-column>
          <el-table-column label="结束展示时间" prop="end_at" sortable="end_at">
            <template #default="scope">
              <p style="font-weight: bold">
                {{ $common.formatTimesToTime(scope.row.end_at) }}
              </p>
            </template>
          </el-table-column>
          <el-table-column label="链接" align="center">
            <template slot-scope="scope">
              <p style="font-weight: bold">{{ scope.row.last_page }}</p>
            </template>
          </el-table-column>
          <el-table-column label="状态" width="120" align="center">
            <template slot-scope="scope">
              <el-tag
                style="font-weight: bold"
                :type="$common.CommonStatusList[scope.row.status].tag"
                effect="dark"
                >{{ $common.CommonStatusList[scope.row.status].name }}</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column label="发布状态" width="340">
            <template slot-scope="scope">
              <p
                :style="{
                  color: publishStatusResult(scope.row).color,
                }"
              >
                {{ publishStatusResult(scope.row).info }}
              </p>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="140" align="center">
            <template slot-scope="scope">
              <el-button
                style="color: #03aef9"
                @click="checkModelDetail(scope.row)"
                type="text"
                >编辑</el-button
              >
              <el-button
                style="color: #f03c69"
                @click="removeModelOnclick(scope.row)"
                type="text"
                >移除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-main>
      <el-footer class="footer-info">
        <el-pagination
          style="margin-top: 15px"
          background
          layout="sizes,prev, pager, next, jumper"
          :page-sizes="[20, 50, 100, 150]"
          :page-size="page_size"
          @size-change="handleSizeChange"
          :pager-count="9"
          :total="page_total"
          :current-page="page_number"
          @current-change="currentChangeOnclick"
        ></el-pagination>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
export default {
  name: "BannerListView",
  data() {
    const defaultSortFields = "-start_at";
    return {
      default_sort_fields: defaultSortFields,
      sort_fields: defaultSortFields,
      loading: false,
      search_key: "",
      page_status: 0,
      page_total: 0,
      page_size: 20,
      page_number: 1,
      model_list: [],
      class_id: "",
      class_list: [],
      class_list_map: {},
    };
  },
  created() {
    this.class_list_map = new Map();
    this.getClassList();
    this.getModelList();
  },
  methods: {
    getClassList() {
      let _me = this;
      let key = "bannerClassList";
      let val = _me.$common.getGlobalStorage(key);
      if (val) {
        _me.class_list = val;
        for (let i of _me.class_list) {
          _me.class_list_map.set(i.id, i.name);
        }
        return;
      }

      let params = {
        status: 1,
        page_size: 20,
        page_number: 1,
        sort_fields: "",
      };
      _me.loading = true;
      _me.api
        .get_banner_class_list(params)
        .then((res) => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.class_list = res.data.array === undefined ? [] : res.data.array;
            for (let i of _me.class_list) {
              _me.class_list_map.set(i.id, i.name);
            }
            _me.$common.setGlobalStorage(key, _me.class_list);
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err.message || "获取数据失败",
            type: "error",
          });
        });
    },
    getModelList() {
      let _me = this;
      let params = {
        class_id: this.class_id,
        key: this.search_key,
        status: this.page_status,
        page_size: this.page_size,
        page_number: this.page_number,
        sort_fields: this.sort_fields,
      };
      this.loading = true;
      this.api
        .get_banner_list(params)
        .then((res) => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.model_list = res.data.array === undefined ? [] : res.data.array;
            _me.page_total = res.data.total;
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err.message || "获取数据失败",
            type: "error",
          });
        });
    },
    checkModelDetail(model) {
      let path =
        model && model.id ? "banner-detail?id=" + model.id : "banner-detail";
      this.$router.push(path);
    },
    removeModelOnclick(model) {
      let _me = this;
      _me
        .$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          _me.removeItem(model);
        })
        .catch(() => {
          // eslint-disable-next-line no-console
        });
    },
    removeItem(model) {
      let _me = this;
      _me.loading = true;
      let params = {
        id: model.id,
      };
      this.api
        .delete_banner(params)
        .then((res) => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.$message.success("删除成功");
            _me.getModelList();
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err.message || "获取数据失败",
            type: "error",
          });
        });
    },
    currentChangeOnclick(number) {
      this.page_number = number;
      this.getModelList();
    },
    handleSizeChange(val) {
      this.page_size = val;
      this.getModelList();
    },
    publishStatusResult(row) {
      let start_time = new Date(row.start_at).getTime();
      let end_time = new Date(row.end_at).getTime();
      let current_time = new Date().getTime();
      const seven_day = 7 * 24 * 3600 * 1000;
      let obj = {
        info: "",
        color: "",
      };
      // 不是已发布状态就显示-
      if (row.status !== 1) {
        obj = {
          info: "-",
          color: "",
        };
        return obj;
      } else {
        if (current_time < start_time) {
          if (current_time + seven_day > start_time) {
            obj = {
              info: "准备上线（离开始时间小于7天）",
              color: "blue",
            };
            return obj;
          } else {
            obj = {
              info: "-",
              color: "blue",
            };
            return obj;
          }
        } else if (current_time >= start_time && current_time <= end_time) {
          // 开始时间和结束时间时间间隔<7天的情况
          if (end_time - start_time <= seven_day) {
            obj = {
              info: "即将下线（离结束时间小于7天）",
              color: "orange",
            };
            return obj;
          } else {
            if (current_time + seven_day < end_time) {
              obj = {
                info: "正在上线 （大于开始时间，离结束时间大于7天）",
                color: "green",
              };
              return obj;
            } else {
              obj = {
                info: "即将下线（离结束时间小于7天）",
                color: "orange",
              };
              return obj;
            }
          }
        } else {
          obj = {
            info: "已经下线（大于结束时间）",
            color: "red",
          };
          return obj;
        }
      }
    },
    sortTable({ column, prop, order }) {
      this.sort_fields =
        order === null
          ? this.default_sort_fields
          : `${order === "ascending" ? "" : "-"}${prop}`;
      this.getModelList();
    },
    // isWeek(input) {
    //   let timestamp = new Date(input).getTime();
    //   //   判断时间是否在当前时间之后，在当前时间之前就算过期
    //   if (timestamp > new Date().getTime()) {
    //     return (
    //       Math.abs(timestamp - new Date().getTime()) < 7 * 24 * 3600 * 1000
    //     ); //时间差和一周时间的比较
    //   }
    // },
    // isMonth(input) {
    //   let timestamp = new Date(input).getTime();
    //   //   判断时间是否在当前时间之后，在当前时间之前就算过期
    //   if (timestamp > new Date().getTime()) {
    //     const date = new Date();
    //     const year = date.getFullYear();
    //     const month = date.getMonth() + 1;
    //     const day = new Date(year, month, 0).getDate();
    //     return (
    //       Math.abs(timestamp - new Date().getTime()) < 24 * 3600 * 1000 * day
    //     ); //时间差和一周时间的比较
    //   }
    // },
    // tableRowClassName({ row }) {
    //   let timestamp = new Date(row.end_at).getTime();
    //   if (!(timestamp > new Date().getTime())) {
    //     return "warning-row";
    //   } else {
    //     return "";
    //   }
    // },
  },
};
</script>

<style scoped></style>
<style>
/* .isWeek {
  color: #eb6240;
}
.isMonth {
  color: #e39048;
} */

.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
</style>
